export default {
    root: {
        class: 'overflow-x-auto'
    },
    menu: {
        class: [
            // Flexbox
            'flex flex-1',

            // Spacing
            'list-none',
            'p-0 m-0',

            // Colors
            'bg-zinc-0 dark:bg-zinc-900',
            'border-b-2 border-zinc-200 dark:border-zinc-700',
            'text-zinc-900 dark:text-zinc-0/80'
        ]
    },
    menuitem: {
        class: 'mr-0'
    },
    action: ({ context, state }) => ({
        class: [
            'relative',

            // Font
            'font-semibold leading-none',

            // Flexbox and Alignment
            'flex items-center',

            // Spacing
            'py-4 px-[1.125rem]',
            '-mb-px',

            // Shape
            'border-b',
            'rounded-t-md',

            // Colors and Conditions
            {
                'border-zinc-200 dark:border-zinc-700': state.d_activeIndex !== context.index,
                'bg-zinc-0 dark:bg-zinc-900': state.d_activeIndex !== context.index,
                'text-zinc-700 dark:text-zinc-0/80': state.d_activeIndex !== context.index,

                'bg-zinc-0 dark:bg-zinc-900': state.d_activeIndex === context.index,
                'border-accent': state.d_activeIndex === context.index,
                'text-accent': state.d_activeIndex === context.index
            },

            // States
            'focus:outline-none focus:outline-offset-0 focus-visible:ring-1 ring-inset focus-visible:ring-accent-400 dark:focus-visible:ring-accent-300',
            {
                'hover:text-zinc-900 dark:hover:text-zinc-0': state.d_activeIndex !== context.index
            },

            // Transitions
            'transition-all duration-200',

            // Misc
            'cursor-pointer select-none text-decoration-none',
            'overflow-hidden',
            'user-select-none'
        ]
    }),
    icon: {
        class: 'mr-2'
    }
};
