<template>

    <!-- Steps left -->
    <div class="hidden fixed left-20 md:flex flex-col mb-3 gap-2  "
        style="z-index:99; top:50%; transform:translateY(-50%)">
        <Button @click="active = 0" rounded label="1" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 0" />
        <Button @click="active = 1" rounded label="2" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 1" />
        <Button @click="active = 2" rounded label="3" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 2" />
        <Button @click="active = 3" rounded label="4" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 3" />
        <Button @click="active = 4" rounded label="5" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 4" />
    </div>
    <!-- Ende steps -->


    <div class="min-h-screen  flex flex-col relative pt-16">

        <div class="md:max-w-5xl lg:w-[64rem]  px-2 mx-auto">

            <!-- Headline -->

            <div class="headlines mb-8 w-full">

                <!-- smallline -->
                <div class="flex items-center">
                    <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="w-4 xl:w-6 mr-2">
                        <path
                            d="M0 0.0341797H35C46.0457 0.0341797 55 8.98849 55 20.0342V35.0342C55 46.0799 46.0457 55.0342 35 55.0342H20C8.95431 55.0342 0 46.0799 0 35.0342V0.0341797Z"
                            fill="#1F9451" />
                    </svg>
                    <div class="text-sm md:text-xl">
                        Lorem ipsum dolor sit amet
                    </div>
                </div>

                <!-- headline -->
                <h1 class="termine-headline font-bold text-2xl xl:text-4xl uppercase">
                    Energieberatung Online Anfrage
                </h1>

                <!-- subline -->
                <h2 class="text-2xl font-light xl:text-4xl uppercase">
                    Starten Sie Ihre Energiewende
                </h2>
            </div>
            <!-- Ende Headline -->




            <Accordion v-show="isInProgress" v-model:activeIndex="active">
                <AccordionTab header="1. Ich interessiere mich für ... ">

                    <div v-if="!hasInterest" class="my-8 text-red-500">
                        Bitte wählen Sie mindestens eine Dienstleistung aus
                    </div>

                    <div class="grid md:grid-cols-4">
                        <div class="col-span-2">
                            <Checkbox :invalid='!hasInterest' class="interest" v-model="formData.interest.isfp"
                                value="isfp" @change="checkInterests()" binary variant="filled" />
                            <label for="isfp" class="ml-2 cursor-pointer">Individueller Sanierungsfahrplan
                                (iSFP)</label>
                        </div>
                        <div class="col-span-2">
                            <Checkbox :invalid='!hasInterest' @change="checkInterests()" class="interest"
                                v-model="formData.interest.begem" value="begem" binary variant="filled" />
                            <label for="begem" class="ml-2 cursor-pointer">Fördermittelabwicklung</label>
                        </div>
                        <div class="col-span-2">
                            <Checkbox :invalid='!hasInterest' @change="checkInterests()" class="interest"
                                v-model="formData.interest.EWaermeGProofBW" value="ewaermeg" binary variant="filled" />
                            <label for="ewaermeg" class="ml-2 cursor-pointer">EWärmeG-NAchweis BW (15% erneuerbare
                                Energien)</label>
                        </div>
                        <div class="col-span-2">
                            <Checkbox :invalid='!hasInterest' @change="checkInterests()" class="interest"
                                v-model="formData.interest.initialConsultationWeg" value="ibweg" binary
                                variant="filled" />
                            <label for="ibweg" class="ml-2 cursor-pointer">Initialberatung für WEGs</label>
                        </div>
                        <div class="col-span-2">
                            <Checkbox :invalid='!hasInterest' @change="checkInterests()" class="interest"
                                v-model="formData.interest.heatLoadCalculation" value="heizlastberechnung" binary
                                variant="filled" />
                            <label for="heizlastberechnung" class="ml-2 cursor-pointer">Heizlastberechnung für
                                WEGs</label>
                        </div>
                    </div>


                    <div class="my-8">

                        <div id="container-dokumente" class="mb-8">
                            <p class="my-2 font-bold">Bitte halten Sie folgende Dokumente in digitaler Form bereit:</p>
                            <ul id="documents-list">
                                <li id="lageplan">Baubeschreibung + Lageplan</li>
                                <li id="grundrisse">Grundrisse (PDF`s mit Maßketten)</li>
                                <li id="Schornsteinfegermessprotokoll">Schornsteinfeg
                                    ermessprotokoll</li>
                                <li id="ansichten">Ansichten &amp; Schnitte</li>
                                <li id="angebote">Angebote/Rechnungen von Maßnahmen</li>
                                <li id="fotos">Fotos Gebäude &amp; Heizung</li>
                            </ul>
                            <p class="my-2">Falls Sie diese Dokumente im Moment nicht zur Hand haben, ist das kein
                                Problem.<br>
                                Sie können Sie diese auch später postalisch oder per E-Mail nachreichen.

                                <strong>Bitte beachten Sie, dass wir Ihre Anfrage erst bearbeiten können, wenn uns alle
                                    Daten
                                    vorliegen.</strong>
                            </p>
                        </div>
                    </div>

                    <div class="flex justify-end my-2">
                        <Button :disabled="!hasInterest" label="Weiter" @click="active = 1, checkInterests()" />
                    </div>

                </AccordionTab>

                <AccordionTab header="2. Angaben zum Gebäude  ">
                    <HeadingText heading-text="Gebäudedaten" :tag="'h2'" />
                    <div class="grid lg:grid-cols-4 gap-8 mb-8 max-w-screen-sm">
                        <div class="col-span-3">
                            <FloatLabel>
                                <InputText :invalid='isObjectStreetEmpty' @update:modelValue="checkObjectStreet()"
                                    class="w-full" size="large" id="street" v-model="formData.object.street" />
                                <label for="street">Straße*</label>
                            </FloatLabel>
                        </div>

                        <div class="col-span-1">
                            <FloatLabel>
                                <InputText :invalid='isObjectNumberEmpty' @update:modelValue="checkObjectNumber()"
                                    class="w-full" size="large" id="number" v-model="formData.object.number" />
                                <label for="number">Hausnummer*</label>
                            </FloatLabel>
                        </div>

                        <div class="col-span-1">
                            <FloatLabel>
                                <InputText :invalid='isObjectZipEmpty' @update:modelValue="checkObjectZip()"
                                    class="w-full" size="large" id="zip" v-model="formData.object.zip" />
                                <label for="zip">Postleitzahl*</label>
                            </FloatLabel>
                        </div>

                        <div class="col-span-3">
                            <FloatLabel>
                                <InputText :invalid='isObjectPlaceEmpty' @update:modelValue="checkObjectPlace()"
                                    size="large" class="w-full" id="place" v-model="formData.object.place" />
                                <label for="place">Ort*</label>
                            </FloatLabel>
                        </div>
                    </div>

                    <div class="grid lg:grid-cols-3 gap-x-4 mb-8 max-w-screen-sm">
                        <div class="flex align-items-center">
                            <Radiobutton :invalid='isNoType' v-model="formData.object.buildingType"
                                @update:modelValue="isNoType = false" inputId="wg" value="wg" />
                            <label for="wg" class="ml-2 cursor-pointer">Wohngebäude</label>
                        </div>
                        <div class="flex align-items-center">
                            <Radiobutton :invalid='isNoType' v-model="formData.object.buildingType"
                                @update:modelValue="isNoType = false" inputId="nwg" value="nwg" />
                            <label for="nwg" class="ml-2 cursor-pointer">Nichtwohngebäude</label>
                        </div>
                        <div class="flex align-items-center">
                            <Radiobutton :invalid='isNoType' v-model="formData.object.buildingType"
                                @update:modelValue="isNoType = false" inputId="mixed" value="mixed" />
                            <label for="mixed" class="ml-2 cursor-pointer">Gemischt genutztes Gebäude</label>
                        </div>
                    </div>

                    <div class="grid  gap-x-4 gap-y-8 mb-8 ">

                        <div class="flex flex-col">
                            <span class="font-bold mb-4"> Es handelt sich um ein freistehendes Gebäude*</span>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton :invalid="isFreestandingEmpty" v-model="formData.object.freestanding"
                                        @update:modelValue="isFreestandingEmpty = false" inputId="" value="true" />
                                    <label for="true" class="ml-2 cursor-pointer">Ja</label>
                                </div>
                                <div>
                                    <Radiobutton :invalid="isFreestandingEmpty" v-model="formData.object.freestanding"
                                        @update:modelValue="isFreestandingEmpty = false" inputId="" value="false" />
                                    <label for="false" class="ml-2 cursor-pointer">Nein</label>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <span class="font-bold mb-4"> Das Gebäude ist denkmalgeschützt oder es handelt sich um
                                erhaltenswerte Bausubstanz*</span>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton :invalid="isDenkmalschutzEmpty" v-model="formData.object.denkmalschutz"
                                        @update:modelValue="isDenkmalschutzEmpty = false" inputId="" value="true" />
                                    <label for="true" class="ml-2 cursor-pointer">Ja</label>
                                </div>
                                <div>
                                    <Radiobutton :invalid="isDenkmalschutzEmpty" v-model="formData.object.denkmalschutz"
                                        @update:modelValue="isDenkmalschutzEmpty = false" inputId="" value="false" />
                                    <label for="false" class="ml-2 cursor-pointer">Nein</label>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <span class="font-bold mb-4"> Es befinden sich mehrere Gebäude am Standort*</span>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton :invalid="isMehrereGebaeudeEmpty"
                                        v-model="formData.object.mehrereGebaeude"
                                        @update:modelValue="isMehrereGebaeudeEmpty = false" inputId="" value="true" />
                                    <label for="true" class="ml-2 cursor-pointer">Ja</label>
                                </div>
                                <div>
                                    <Radiobutton :invalid="isMehrereGebaeudeEmpty"
                                        v-model="formData.object.mehrereGebaeude"
                                        @update:modelValue="isMehrereGebaeudeEmpty = false" inputId="" value="false" />
                                    <label for="false" class="ml-2 cursor-pointer">Nein</label>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <span class="font-bold mb-4">Lüftungsanlage vorhanden*</span>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton :invalid="hasLueftungsanlageEmpty"
                                        v-model="formData.object.lueftungsanlage"
                                        @update:modelValue="hasLueftungsanlageEmpty = false" inputId="" value="true" />
                                    <label for="true" class="ml-2 cursor-pointer">Ja</label>
                                </div>
                                <div>
                                    <Radiobutton :invalid="hasLueftungsanlageEmpty"
                                        v-model="formData.object.lueftungsanlage"
                                        @update:modelValue="hasLueftungsanlageEmpty = false" inputId="" value="false" />
                                    <label for="false" class="ml-2 cursor-pointer">Nein</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid md:grid-cols-2 gap-x-4 gap-y-8 mb-8">
                        <div class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="bauantrag">
                                <span>Erster Bauantrag Gebäude*</span>
                                <svg @click="visible = true; modalHeader = 'Erster Bauantrag Gebäude'; modalInfo = 'Geben Sie hier möglichst das genaue Datum des ersten Bauantrages an. Falls Sie dieses nicht wissen, so geben Sie das Baujahr an. '"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg>
                            </label>
                            <InputMask :invalid="objectBauantragEmpty" id="bauantrag" v-model="formData.object.baujahr"
                                placeholder="01/01/1970" mask="99/99/9999" slotChar="mm/dd/yyyy" />
                        </div>
                    </div>

                    <div class="grid md:grid-cols-2 gap-x-4 gap-y-8 mb-8">

                        <div v-if="formData.object.buildingType == 'wg' || formData.object.buildingType == 'mixed'"
                            class="col-span-1">

                            <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                <span>Anzahl der Wohneinheiten im Gebäude*</span>
                                <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg>
                            </label>
                            <InputNumber :invalid="objectCountWEEmpty" v-model="formData.object.countWE"
                                @update:modalValue="objectCountWEEmpty = false" aria-placeholder=""
                                :useGrouping="false" />
                        </div>

                        <div v-if="formData.object.buildingType == 'wg' && formData.interest.begem" class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                <span>Betroffene Wohneinheiten*</span>
                                <svg @click="visible = true; modalHeader = 'Betroffene Wohneinheiten'; modalInfo = 'Wenn Sie z.B. die Fenster nur in einer Wohneinheit tauschen, so geben Sie dies hier an.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg>
                            </label>
                            <InputNumber :invalid="involvedWEEmpty" v-model="formData.object.involvedWe"
                                @update:modalValue="involvedWEEmpty = false" aria-placeholder="" :useGrouping="false" />
                        </div>

                        <div v-if="formData.object.buildingType == 'wg' && formData.interest.isfp"
                            class="flex flex-col">
                            <label class="flex gap-2 items-center" for="countWE">
                                <span class="font-bold mb-4">Durch die Sanierung entstehen neue Wohneinheiten</span>
                                <!-- <svg @click="visible = true; modalHeader='Betroffene Wohneinheiten'; modalInfo='Wenn Sie z.B. die Fenster nur in einer Wohneinheit tauschen, so geben Sie dies hier an.'"  
                                xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton v-model="formData.object.newWe" inputId="" value="true" />
                                    <label for="true" class="ml-2 cursor-pointer">Ja</label>
                                </div>
                                <div>
                                    <Radiobutton v-model="formData.object.newWe" inputId="" value="false" />
                                    <label for="false" class="ml-2 cursor-pointer">Nein</label>
                                </div>
                            </div>
                        </div>

                        <div v-if="formData.object.buildingType == 'wg' && formData.interest.isfp" class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                <span>Wohneinheiten nach der Sanierung</span>
                                <!-- <svg @click="visible = true; modalHeader='Betroffene Wohneinheiten'; modalInfo='Wenn Sie z.B. die Fenster nur in einer Wohneinheit tauschen, so geben Sie dies hier an.'"  
                                xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <InputNumber v-model="formData.object.newCountWE" aria-placeholder=""
                                :useGrouping="false" />
                        </div>

                        <div v-if="formData.object.buildingType == 'nwg' || formData.object.buildingType == 'mixed'"
                            class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                <span>Gewerblich genutzte Fläche in m² *</span>
                                <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <InputNumber :invalid="gewerbeflaecheEmpty" v-model="formData.object.gewerbeflaeche"
                                @update:modalValue="gewerbeflaecheEmpty = false" aria-placeholder=""
                                :useGrouping="false" />
                        </div>

                        <div v-if="formData.object.buildingType == 'mixed'" class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                <span>Wohnfläche in m² *</span>
                                <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <InputNumber :invalid="wohnflaecheEmpty" v-model="formData.object.wohnflaeche"
                                @update:modalValue="wohnflaecheEmpty = false" aria-placeholder=""
                                :useGrouping="false" />
                        </div>

                    </div>

                    <HeadingText heading-text="Angaben zur Heizungsanlage" :tag="'h2'" />


                    <div class="grid gap-x-4 gap-y-8 mb-8 max-w-screen-sm">
                        <div class="flex flex-col">
                            <label class="flex gap-2 items-center" for="countWE">
                                <span class="font-bold mb-4">Art der Beheizung</span>
                                <!-- <svg @click="visible = true; modalHeader='Betroffene Wohneinheiten'; modalInfo='Wenn Sie z.B. die Fenster nur in einer Wohneinheit tauschen, so geben Sie dies hier an.'"  
                            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                <path fill="currentColor" fill-rule="evenodd"
                                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                    clip-rule="evenodd" />
                            </svg> -->
                            </label>
                            <div class="flex gap-4">
                                <div>
                                    <Radiobutton v-model="formData.object.heatingType" inputId="" value="zentral" />
                                    <label for="zentral" class="ml-2 cursor-pointer">zentral</label>
                                </div>
                                <div>
                                    <Radiobutton v-model="formData.object.heatingType" inputId="" value="dezentral" />
                                    <label for="dezentral" class="ml-2 cursor-pointer">dezentral</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex gap-x-4 gap-y-8 mb-8 max-w-screen-sm">

                        <div class="">
                            <label class="flex gap-2 items-center font-bold mb-4" for="">
                                <span>Brennstoff</span>
                                <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <Dropdown  :options='brennstoff' v-model='formData.object.heatingFuel'
                                option-label='name' placeholder='Bitte wählen' />
                        </div>

                        <div class="">
                            <label class="flex gap-2 items-center font-bold mb-4" for="">
                                <span>Durchschnittlicher Brennstoffverbrauch</span>
                                <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <div class="flex gap-4 items-center">
                            <InputNumber  v-model="formData.object.heatingConsumption" aria-placeholder=""
                                :useGrouping="false" /> <span class="einheit">Einheit</span>
                            </div>
                        </div>
                    </div>


                    <div class="grid md:grid-cols-2 gap-x-4 gap-y-8 mb-8 max-w-screen-sm">
                        <div class="col-span-1">
                            <label class="flex gap-2 items-center font-bold mb-4" for="zip">
                                <span>Baujahr der Heizugsanlage</span>
                                <!-- <svg @click="visible = true; modalHeader = 'Erster Bauantrag Gebäude'; modalInfo = 'Geben Sie hier möglichst das genaue Datum des ersten Bauantrages an. Falls Sie dieses nicht wissen, so geben Sie das Baujahr an. '"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                            </label>
                            <InputNumber :useGrouping="false" v-model="formData.object.heatingYear"
                                placeholder="1970" />
                        </div>

                    </div>

                    <div class="grid md:grid-cols-2 gap-x-4 gap-y-8 mb-8 max-w-screen-sm">
    

                        <div class="col-span-2 items-center justify-center">
                            <Checkbox v-model="formData.object.heatingYearUnknown" value="heatingYearUnknown" binary
                                variant="filled" />
                            <label for="heatingYearUnknown" class="ml-2 cursor-pointer">Baujahr der Heizungsanlage nicht
                                bekannt</label>
                        </div>
                    </div>





                    <div class="flex justify-end my-2">
                        <Button label="Weiter" @click="active = 2, checkSecondTab()" />
                    </div>
                </AccordionTab>
                <!-- Ende Gebäudetab -->

                <!-- Angaben zur Heizung -->
                <AccordionTab header="3. Angaben zur Gebäudesanierung">

                    <HeadingText heading-text="Bereits durchgeführte Maßnahmen" :tag="'h2'" />

                    <p class="mb-8">
                        Geben Sie hier bitte, soweit bekannt, alle Maßnahmen an, die seit Baubeginn am Gebäude
                        stattgefunden haben.
                    </p>
                    <div class="font-bold mb-8">
                        Bauliche Maßnahmen
                    </div>

                    <div class="grid lg:grid-cols-12 gap-x-4 gap-y-4 mb-8">

                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.daemmungWaende" value="true" binary
                                variant="filled" />
                            <label for="daemmungWaende" class="ml-2 cursor-pointer">
                                Dämmung von Wänden
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.daemmungWaende">
                                <FloatLabel>
                                    <InputText class="w-1/2 " size="small"
                                        v-model="formData.measuresImplemented.daemmungWaendeYear" />
                                    <label for="">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.daemmungDach" value="true" binary
                                variant="filled" />
                            <label for="daemmungWaende" class="ml-2 cursor-pointer">
                                Dämmung von Dachflächen
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.daemmungDach">
                                <FloatLabel>
                                    <InputText class="w-1/2" size="small"
                                        v-model="formData.measuresImplemented.daemmungDachYear" />
                                    <label for="daemmungDach">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.daemmungKeller" value="true" binary
                                variant="filled" />
                            <label for="daemmungKeller" class="ml-2 cursor-pointer">
                                Dämmung Kellerdecke
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.daemmungKeller">
                                <FloatLabel>
                                    <InputText class="w-1/2 " size="small"
                                        v-model="formData.measuresImplemented.daemmungKellerYear" />
                                    <label for="jahr">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.fenster" value="true" binary
                                variant="filled" />
                            <label for="fenster" class="ml-2 cursor-pointer">
                                Austausch Fenster
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.fenster">
                                <FloatLabel>
                                    <InputText class="w-1/2 " size="small"
                                        v-model="formData.measuresImplemented.fensterYear" />
                                    <label for="jahr">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.aussentuer" value="true" binary
                                variant="filled" />
                            <label for="fenster" class="ml-2 cursor-pointer">
                                Austausch Außentür
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.aussentuer">
                                <FloatLabel>
                                    <InputText class="w-1/2 " size="small"
                                        v-model="formData.measuresImplemented.aussentuerYear" />
                                    <label for="street">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                    </div>

                    <div class="font-bold  mb-8">
                        Anlagentechnik
                    </div>

                    <div class="grid lg:grid-cols-6 gap-x-4 gap-y-8 mb-8">

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.pvAnlage" value="true" binary
                                variant="filled" />
                            <label for="pvAnlage" class="ml-2 cursor-pointer">
                                PV-Anlage
                            </label>
                        </div>

                        <div class="col-span-3 flex gap-4">
                            <div class="mb-6" v-show="formData.measuresImplemented.pvAnlage">
                                <FloatLabel>
                                    <InputText class="w-1/2 " size="small"
                                        v-model="formData.measuresImplemented.pvAnlageYear" />
                                    <label for="pvAnlage">Jahr</label>
                                </FloatLabel>
                            </div>
                            <div v-show="formData.measuresImplemented.pvAnlage">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.pvAnlageKW" />
                                    <label for="pvAnlage">kw</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende -->


                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.thermischeSolaranlage" value="true" binary
                                variant="filled" />
                            <label for="thermischeSolaranlage" class="ml-2 cursor-pointer">
                                Thermische Solaranlage
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.thermischeSolaranlage">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.thermischeSolaranlageYear" />
                                    <label for="thermischeSolaranlage">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.smartHome" value="true" binary
                                variant="filled" />
                            <label for="smartHome" class="ml-2 cursor-pointer">
                                Smart Home
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.smartHome">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.smartHomeYear" />
                                    <label for="thermischeSolaranlage">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.austauschHeizung" value="true" binary
                                variant="filled" />
                            <label for="austauschHeizung" class="ml-2 cursor-pointer">
                                Austausch der Heizung
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.austauschHeizung">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.austauschHeizungYear" />
                                    <label for="austauschHeizung">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-3">
                            <Checkbox v-model="formData.measuresImplemented.heizungsoptimierung" value="true" binary
                                variant="filled" />
                            <label for="austauschHeizung" class="ml-2 cursor-pointer">
                                Heizungsoptimierung
                            </label>
                        </div>

                        <div class="col-span-3">
                            <div v-show="formData.measuresImplemented.heizungsoptimierung">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.heizungsoptimierungYear" />
                                    <label for="austauschHeizung">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">

                            <Checkbox v-model="formData.measuresImplemented.sommerlicherWaermeschutz" value="true"
                                binary variant="filled" />
                            <label for="sommerlicherWaermeschutz" class="ml-2 cursor-pointer">
                                Sommerlicher Wärmeschutz
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.sommerlicherWaermeschutz">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.sommerlicherWaermeschutzYear" />
                                    <label for="sommerlicherWaermeschutz">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.brennstoffzellenheizung" value="true" binary
                                variant="filled" />
                            <label for="brennstoffzellenheizung" class="ml-2 cursor-pointer">
                                Brennstoffzellenheizung
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.brennstoffzellenheizung">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.brennstoffzellenheizungYear" />
                                    <label for="brennstoffzellenheizung">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.biomasseheizung" value="true" binary
                                variant="filled" />
                            <label for="biomasseheizung" class="ml-2 cursor-pointer">
                                Biomasseheizung
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.biomasseheizung">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.biomasseheizungYear" />
                                    <label for="biomasseheizung">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.eeHybridheizung" value="true" binary
                                variant="filled" />
                            <label for="eeHybridheizung" class="ml-2 cursor-pointer">
                                EE-Hybridheizung
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.eeHybridheizung">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.eeHybridheizungYear" />
                                    <label for="eeHybridheizung">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.anschlussWarmenetz" value="true" binary
                                variant="filled" />
                            <label for="anschlussWarmenetz" class="ml-2 cursor-pointer">
                                Anschluss an ein Wärmenetz
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.anschlussWarmenetz">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.anschlussWarmenetzYear" />
                                    <label for="anschlussWarmenetz">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.anschlussGebaeudenetz" value="true" binary
                                variant="filled" />
                            <label for="anschlussGebaeudenetz" class="ml-2 cursor-pointer">
                                Anschluss an ein Gebäudenetz
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.anschlussGebaeudenetz">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.anschlussGebaeudenetzYear" />
                                    <label for="anschlussGebaeudenetz">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.waermepumpe" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Wärmepumpe
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.waermepumpe">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.waermepumpeYear" />
                                    <label for="waermepumpe">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.anschlussGeb75" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Anschuss an ein Gebäudenetz (75%)
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.anschlussGeb75">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.anschlussGeb75Year" />
                                    <label for="anschlussGeb75">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.anschlussGeb25" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Anschuss an ein Gebäudenetz (25%)
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.anschlussGeb25">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.anschlussGeb25Year" />
                                    <label for="anschlussGeb25">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div class="col-span-2">
                            <Checkbox v-model="formData.measuresImplemented.effizienzhaus" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Effizienzhaus
                            </label>
                        </div>

                        <div>
                            <div v-show="formData.measuresImplemented.effizienzhaus">
                                <FloatLabel>
                                    <InputText class="max-w-full " size="small"
                                        v-model="formData.measuresImplemented.effizienzhausYear" />
                                    <label for="effizienzhaus">Jahr</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <!-- Ende Maßnahme -->
                    </div>

                    <div class="flex flex-col gap-8  mb-8">
                        <div>
                            <Checkbox v-model="formData.measuresImplemented.sonstiges" value="true" binary
                                variant="filled" />
                            <label for="sonstiges" class="ml-2 cursor-pointer">
                                Sonstiges
                            </label>
                        </div>


                        <Textarea v-show="formData.measuresImplemented.sonstigesMessage" v-model="value" autoResize
                            rows="5" cols="30" />

                    </div>

                    <HeadingText heading-text="Geplante Maßnahmen" :tag="'h2'" />


                    <div class="font-bold mb-8">
                        Bauliche Maßnahmen
                    </div>

                    <div class="grid lg:grid-cols-3 gap-x-4 gap-y-4 mb-8">

                        <div>
                            <Checkbox v-model="formData.measuresPlanned.daemmungWaende" value="true" binary
                                variant="filled" />
                            <label for="daemmungWaende" class="ml-2 cursor-pointer">
                                Dämmung von Wänden
                            </label>
                        </div>


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.daemmungDach" value="true" binary
                                variant="filled" />
                            <label for="daemmungWaende" class="ml-2 cursor-pointer">
                                Dämmung von Dachflächen
                            </label>
                        </div>


                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.daemmungKeller" value="true" binary
                                variant="filled" />
                            <label for="daemmungKeller" class="ml-2 cursor-pointer">
                                Dämmung Kellerdecke
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.fenster" value="true" binary variant="filled" />
                            <label for="fenster" class="ml-2 cursor-pointer">
                                Austausch Fenster
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.aussentuer" value="true" binary
                                variant="filled" />
                            <label for="fenster" class="ml-2 cursor-pointer">
                                Austausch Außentür
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                    </div>

                    <div class="font-bold  mb-8">
                        Anlagentechnik
                    </div>

                    <div class="grid lg:grid-cols-3 gap-x-4 gap-y-4 mb-8">

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.pvAnlage" value="true" binary
                                variant="filled" />
                            <label for="pvAnlage" class="ml-2 cursor-pointer">
                                PV-Anlage
                            </label>
                        </div>
                        <!-- Ende -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.thermischeSolaranlage" value="true" binary
                                variant="filled" />
                            <label for="thermischeSolaranlage" class="ml-2 cursor-pointer">
                                Thermische Solaranlage
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.smartHome" value="true" binary
                                variant="filled" />
                            <label for="smartHome" class="ml-2 cursor-pointer">
                                Smart Home
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.austauschHeizung" value="true" binary
                                variant="filled" />
                            <label for="austauschHeizung" class="ml-2 cursor-pointer">
                                Austausch der Heizung
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.heizungsoptimierung" value="true" binary
                                variant="filled" />
                            <label for="austauschHeizung" class="ml-2 cursor-pointer">
                                Heizungsoptimierung
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.sommerlicherWaermeschutz" value="true" binary
                                variant="filled" />
                            <label for="sommerlicherWaermeschutz" class="ml-2 cursor-pointer">
                                Sommerlicher Wärmeschutz
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.brennstoffzellenheizung" value="true" binary
                                variant="filled" />
                            <label for="brennstoffzellenheizung" class="ml-2 cursor-pointer">
                                Brennstoffzellenheizung
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.biomasseheizung" value="true" binary
                                variant="filled" />
                            <label for="biomasseheizung" class="ml-2 cursor-pointer">
                                Biomasseheizung
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.eeHybridheizung" value="true" binary
                                variant="filled" />
                            <label for="eeHybridheizung" class="ml-2 cursor-pointer">
                                EE-Hybridheizung
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.anschlussWarmenetz" value="true" binary
                                variant="filled" />
                            <label for="anschlussWarmenetz" class="ml-2 cursor-pointer">
                                Anschluss an ein Wärmenetz
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.anschlussGebaeudenetz" value="true" binary
                                variant="filled" />
                            <label for="anschlussGebaeudenetz" class="ml-2 cursor-pointer">
                                Anschluss an ein Gebäudenetz
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.waermepumpe" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Wärmepumpe
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.anschlussGeb75" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Anschuss an ein Gebäudenetz (75%)
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->


                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.anschlussGeb25" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Anschuss an ein Gebäudenetz (25%)
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->

                        <!-- Maßnahme -->
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.effizienzhaus" value="true" binary
                                variant="filled" />
                            <label for="waermepumpe" class="ml-2 cursor-pointer">
                                Effizienzhaus
                            </label>
                        </div>
                        <!-- Ende Maßnahme -->
                    </div>

                    <div class="flex flex-col gap-8">
                        <div>
                            <Checkbox v-model="formData.measuresPlanned.sonstiges" value="true" binary
                                variant="filled" />
                            <label for="sonstiges" class="ml-2 cursor-pointer">
                                Sonstiges
                            </label>
                        </div>


                        <Textarea v-show="formData.measuresPlanned.sonstigesMessage" v-model="value" autoResize rows="5"
                            cols="30" />

                    </div>

                    <div class="flex justify-end my-2">
                        <Button label="Weiter" @click="active = 3" />
                    </div>


                </AccordionTab>
                <!-- Ende Angaben zur Heizung -->




                <AccordionTab header="4. Eigentümer und Ansprechpartner">

                    <div class="mb-8">
                        <HeadingText heading-text="Ich stelle diese Anfrage als" :tag="'div'" />
                        <div class="flex gap-4 items-center">
                            <Dropdown :options='contactType' v-model='formData.contact.contactType' option-label='name'
                                placeholder='Bitte wählen' />

                            <svg @click="visible = true; modalHeader = 'Wann wähle ich Verwalter?'; modalInfo = 'Verwalter können Sie wählen, wenn Sie nicht selbst Eigentümer des Gebäudes sind, sich aber um die Abwicklung mit uns kümmern, u.a. als Hausverwalter, aber auch Verwandte des Eigentümers.'"
                                xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary-500 hover:cursor-pointer"
                                viewBox="0 0 24 24">
                                <path fill="currentColor" fill-rule="evenodd"
                                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                    clip-rule="evenodd" />
                            </svg>

                        </div>
                    </div>

                    <div class="mb-8">
                        <HeadingText heading-text="Bei dem Antragsteller und Eigentümer handelt es sich um"
                            :tag="'div'" />
                        <Dropdown :options='applicantType' v-model='formData.owner.applicantType' option-label='name'
                            placeholder='Bitte wählen' />
                    </div>

                    <div class="col-span-2 mb-8">
                        <Checkbox v-model="formData.vorsteuer" value="vorsteuerabzug" binary variant="filled" />
                        <label for="isfp" class="ml-2 cursor-pointer">Der Antragsteller ist
                            vorsteuerabzugsberechtigt</label>
                    </div>

                    <!-- Angaben zum verwalter -->

                    <div v-if="formData.contact.contactType.code == 'verwalter'">
                        <HeadingText heading-text="Kontaktdaten des Verwalters" :tag="'div'" />

                        <div class="grid lg:grid-cols-4 gap-x-4 gap-y-8 mb-8 ">

                            <div class="col-span-4">
                                <FloatLabel>
                                    <InputText size="large" class="w-full" id="street"
                                        v-model="formData.contact.company" />
                                    <label for="company">Name der Hausverwaltung</label>
                                </FloatLabel>
                            </div>

                            <!-- Anrede -->
                            <div class="col-span-1 w-full">
                                <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                    <span>Anrede</span>
                                    <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                                </label>
                                <Dropdown :invalid="isContactSalutationEmpty"
                                    @update:modalValue="isContactSalutationEmpty = false" size="large" class="w-full"
                                    :options='salutation' v-model='formData.contact.salutation' option-label='name'
                                    placeholder='Bitte wählen' />
                            </div>
                            <!-- Ende Anrede -->
                        </div>
                        <div class="grid lg:grid-cols-4 gap-x-4 gap-y-8 mb-8 ">


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactFirstNameEmpty" size="large" class="w-full"
                                        id="firstName" v-model="formData.contact.firstName"
                                        @update:modalValue="isContactFirstNameEmpty = false" />
                                    <label for="firstName">Vorname</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactLastNameEmpty" size="large" class="w-full"
                                        id="lastName" v-model="formData.contact.lastName"
                                        @update:modalValue="isContactLastNameEmpty = false" />
                                    <label for="lastName">Nachname</label>
                                </FloatLabel>
                            </div>



                            <div class="col-span-3">
                                <FloatLabel>
                                    <InputText :invalid="isContactStreetEmpty"
                                        @update:modalValue="isContactStreetEmpty = false" size="large" class="w-full"
                                        id="street" v-model="formData.contact.street" />
                                    <label for="street">Straße</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-1">
                                <FloatLabel>
                                    <InputText :invalid="isContactNumberEmpty"
                                        @update:modalValue="isContactNumberEmpty = false" size="large" class="w-full"
                                        id="number" v-model="formData.contact.number" />
                                    <label for="number">Hausnummer</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-1">
                                <FloatLabel>
                                    <InputText :invalid="isContactZipEmpty"
                                        @update:modalValue="isContactZipEmpty = false" size="large" class="w-full"
                                        id="zip" v-model="formData.contact.zip" />
                                    <label for="zip">Postleitzahl</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-3">
                                <FloatLabel>
                                    <InputText :invalid="isContactPlaceEmpty"
                                        @update:modalValue="isContactPlaceEmpty = false" size="large" class="w-full"
                                        id="place" v-model="formData.contact.place" />
                                    <label for="place">Ort</label>
                                </FloatLabel>
                            </div>


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactPrephoneEmpty"
                                        @update:modalValue="isContactPrephoneEmpty = false" size="large" class="w-full"
                                        id="phonePre" v-model="formData.contact.phonepre" />
                                    <label for="street">Telefon Vorwahl</label>
                                </FloatLabel>
                            </div>
                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactPhoneEmpty"
                                        @update:modalValue="isContactPhoneEmpty = false" size="large" class="w-full"
                                        id="phone" v-model="formData.contact.phone" />
                                    <label for="street">Telefon Nummer</label>
                                </FloatLabel>
                            </div>


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactPremobileEmpty"
                                        @update:modalValue="isContactPremobileEmpty = false" size="large" class="w-full"
                                        id="phonePre" v-model="formData.contact.mobilepre" />
                                    <label for="street">Handy Vorwahl</label>
                                </FloatLabel>
                            </div>
                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isContactMobileEmpty"
                                        @update:modalValue="isContactMobileEmpty = false" size="large" class="w-full"
                                        id="phone" v-model="formData.contact.mobile" />
                                    <label for="street">Handy Nummer</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-4">
                                <FloatLabel>
                                    <InputText :invalid="isContactMailEmpty"
                                        @update:modalValue="isContactMailEmpty = false" size="large" class="w-full"
                                        id="emai" v-model="formData.contact.email" />
                                    <label for="street">E-Mail Adresse</label>
                                </FloatLabel>
                            </div>

                        </div>



                    </div>



                    <div>
                        <HeadingText heading-text="Kontaktdaten des Eigentümers" :tag="'div'" />

                        <div class="grid lg:grid-cols-4 gap-x-4 gap-y-8 mb-8 ">

                            <div v-if="formData.owner.applicantType.code !== 'privatperson' && formData.owner.applicantType.code !== 'freiberufler'"
                                class="col-span-4">
                                <FloatLabel>
                                    <InputText size="large" class="w-full" id="street"
                                        v-model="formData.owner.company" />
                                    <label for="company">{{ formData.owner.applicantType.name }}</label>
                                </FloatLabel>
                            </div>

                            <!-- Anrede -->
                            <div class="col-span-1 w-full">
                                <label class="flex gap-2 items-center font-bold mb-4" for="countWE">
                                    <span>Anrede</span>
                                    <!-- <svg @click="visible = true; modalHeader = 'Wohneinheit'; modalInfo = 'Als Wohneinheit wird die Gesamtheit von einzelnen oder zusammenliegenden Räumen, die nach außen abgeschlossen, zu Wohnzwecken bestimmt sind und die Führung eines eigenen Haushalts ermöglichen, bezeichnet.'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-primary-500 hover:cursor-pointer" viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22m0-4.25a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75M12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd" />
                                </svg> -->
                                </label>
                                <Dropdown :invalid="isOwnerSalutationEmpty"
                                    @update:modalValue="isOwnerSalutationEmpty = false" size="large" class="w-full"
                                    :options='salutation' v-model='formData.owner.salutation' option-label='name'
                                    placeholder='Bitte wählen' />
                            </div>
                            <!-- Ende Anrede -->
                        </div>
                        <div class="grid lg:grid-cols-4 gap-x-4 gap-y-8 mb-8 ">


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerFirstNameEmpty"
                                        @update:modalValue="isOwnerFirstNameEmpty = false" size="large" class="w-full"
                                        id="firstName" v-model="formData.owner.firstName" />
                                    <label for="street">Vorname</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerLastNameEmpty"
                                        @update:modalValue="isOwnerLastNameEmpty = false" size="large" class="w-full"
                                        id="lastName" v-model="formData.owner.lastName" />
                                    <label for="street">Nachname</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-4">
                                <Checkbox v-model="adressIsObject" @change="fillAdress(adressIsObject)" value="true"
                                    binary variant="filled" />
                                <label for="adressIsObject" class="ml-2 cursor-pointer">
                                    Adresse des Eigentümers entspricht der Gebäudeadresse</label>
                            </div>


                            <div class="col-span-3">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerStreetEmpty"
                                        @update:modalValue="isOwnerStreetEmpty = false" size="large" class="w-full"
                                        id="street" v-model="formData.owner.street" />
                                    <label for="street">Straße</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-1">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerNumberEmpty"
                                        @update:modalValue="isOwnerNumberEmpty = false" size="large" class="w-full"
                                        id="number" v-model="formData.owner.number" />
                                    <label for="number">Hausnummer</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-1">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerZipEmpty" @update:modalValue="isOwnerZipEmpty = false"
                                        size="large" class="w-full" id="zip" v-model="formData.owner.zip" />
                                    <label for="number">Postleitzahl</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-3">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerPlaceEmpty"
                                        @update:modalValue="isOwnerPlaceEmpty = false" size="large" class="w-full"
                                        id="place" v-model="formData.owner.place" />
                                    <label for="place">Ort</label>
                                </FloatLabel>
                            </div>


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerPrephoneEmpty"
                                        @update:modalValue="isOwnerPrephoneEmpty = false" size="large" class="w-full"
                                        id="phonePre" v-model="formData.owner.phonepre" />
                                    <label for="street">Telefon Vorwahl</label>
                                </FloatLabel>
                            </div>
                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerPhoneEmpty"
                                        @update:modalValue="isOwnerPhoneEmpty = false" size="large" class="w-full"
                                        id="phone" v-model="formData.owner.phone" />
                                    <label for="street">Telefon Nummer</label>
                                </FloatLabel>
                            </div>


                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerPremobileEmpty"
                                        @update:modalValue="isOwnerPremobileEmpty = false" size="large" class="w-full"
                                        id="phonePre" v-model="formData.owner.mobilepre" />
                                    <label for="street">Handy Vorwahl</label>
                                </FloatLabel>
                            </div>
                            <div class="col-span-2">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerMobileEmpty"
                                        @update:modalValue="isOwnerMobileEmpty = false" size="large" class="w-full"
                                        id="phone" v-model="formData.owner.mobile" />
                                    <label for="street">Handy Nummer</label>
                                </FloatLabel>
                            </div>

                            <div class="col-span-4">
                                <FloatLabel>
                                    <InputText :invalid="isOwnerMailEmpty" @update:modalValue="isOwnerMailEmpty = false"
                                        size="large" class="w-full" id="emai" v-model="formData.owner.email" />
                                    <label for="street">E-Mail Adresse</label>
                                </FloatLabel>
                            </div>


                            <div v-if="formData.contact.contactType.code == 'wohnungseigentuemer'" class="col-span-2">
                                <FloatLabel>
                                    <InputText size="large" class="w-full" id="number"
                                        v-model="formData.owner.flatNumber" />
                                    <label for="street">Wohnungsnummer {{ formData.contact.contactType.code }}</label>
                                </FloatLabel>
                            </div>
                            <div v-if="formData.contact.contactType.code == 'wohnungseigentuemer'" class="col-span-2">
                                <FloatLabel>
                                    <InputNumber size="large" class="w-full" id="size"
                                        v-model="formData.owner.flatSize" />
                                    <label for="street">Wohnungsgröße in m²</label>
                                </FloatLabel>
                            </div>

                        </div>



                    </div>


                    <div class="flex justify-end my-2">
                        <Button label="Weiter" @click="active = 4, checkFourthTab()" />
                    </div>


                </AccordionTab>




                <AccordionTab header="5. Dateien hochladen">


                    <div class="flex flex-col mb-4">
                        <FileUpload chooseLabel="Grundrisse hinzufügen" name="grundrisse[]" url="/api/upload"
                            @upload="onAdvancedUpload($event)" :multiple="true" v-model="formData.test" accept="image/*"
                            :maxFileSize="1000000">
                            <template #empty>
                                <p>Drag and drop files to here to upload.</p>
                            </template>
                        </FileUpload>
                    </div>

                    <div class="flex flex-col mb-4">
                        <FileUpload chooseLabel="Lageplan hinzufügen" name="Lageplan[]" url="/api/upload"
                            @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">
                            <template #empty>
                                <p>Drag and drop files to here to upload.</p>
                            </template>
                        </FileUpload>
                    </div>

                    <div class="flex flex-col mb-4">
                        <FileUpload chooseLabel="Ansichten & Schnitte hinzufügen" name="schnitte[]" url="/api/upload"
                            @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">
                            <template #empty>
                                <p>Drag and drop files to here to upload.</p>
                            </template>
                        </FileUpload>
                    </div>

                    <div class="flex flex-col mb-4">
                        <FileUpload chooseLabel="Fotos Gebäude/Heizung hinzufügen" name="schnitte[]" url="/api/upload"
                            @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">
                            <template #empty>
                                <p>Drag and drop files to here to upload.</p>
                            </template>
                        </FileUpload>
                    </div>




                    <div class="flex justify-end my-2">
                        <Button label="Eingabe überprüfen" @click="checkForm()"> </Button>
                    </div>
                </AccordionTab>

            </Accordion>

            <div v-show="checkData">
                <div class="flex flex-col">
                    <HeadingText heading-text="Gebäudedaten" :tag="'h2'" />

                    <p class="mb-4">
                        {{ formData.object.street }} {{ formData.object.number }} <br>
                        {{ formData.object.zip }} {{ formData.object.place }} <br>
                    </p>
                    <div class="mb-4">
                        <div v-if="formData.object.buildingType == 'wg'">
                            Wohngebäude, {{ formData.object.countWE }} Wohneinheiten
                        </div>
                        <div v-if="formData.object.buildingType == 'nwg'">
                            Nichtwohngebäude, {{ formData.object.gewerbeflaeche }} Gewerbefläche
                        </div>
                        <div v-if="formData.object.buildingType == 'mixed'">
                            Gemischt genutztes Gebäude {{ formData.object.countWE }} Wohneinheiten <br>
                            {{ formData.object.gewerbeflaeche }} Gewerbefläche, {{ formData.object.wohnflaeche }}
                            Wohnfläche
                        </div>
                    </div>

                    <div class="mb-4">
                        <div v-if="formData.object.freestanding" class="flex gap-2 items-center ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
                            </svg>
                            Freistehendes Gebäude
                        </div>
                        <div v-if="formData.object.denkmalschutz" class="flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
                            </svg>
                            Es besteht Denkmalschutz oder erhaltenswerte
                            Bausubstanz
                        </div>
                        <div v-if="formData.object.mehrereGebaeude" class="flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
                            </svg>
                            Es befinden sich mehrere Gebäude am
                            Standort
                        </div>
                        <div v-if="formData.object.lueftungsanlage" class="flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
                            </svg>
                            Lüftungsanage vorhanden
                        </div>
                    </div>

                    <div class="mb-4">
                        Datum des ersten Bauantrages: {{ formData.object.baujahr }}
                    </div>
                    
                    <div class="mb-4">
                        Heizung: {{ formData.object.heatingType }}<br>
                        Brennstoff: {{ formData.object.heatingFuel.name }}<br>
                        Verbrauch: {{ formData.object.heatingConsumption }}<br>
                        Baujahr: {{ formData.object.heatingYear }}<br>

                    </div>





            </div>
            {{ formData }}



            <div class="flex justify-end my-2">
                <Button label="zurück" @click="isInProgress = true; checkData = false;"> </Button>
            </div>
        </div>


    </div>

    <Dialog v-model:visible="visible" modal :header="modalHeader" :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        {{ modalInfo }}

    </Dialog>

    </div>






</template>

<!-- primevue org v3 -->

<script setup lang='js'>
import { onMounted, ref } from "vue";
import { FormService } from "../services/FormService.js"

import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import Radiobutton from "primevue/radiobutton";
import HeadingText from "./ui/HeadingText.vue";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import FloatLabel from 'primevue/floatlabel';
import InputMask from 'primevue/inputmask';
import Textarea from 'primevue/textarea';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from "primevue/button";
import Dialog from 'primevue/dialog';

import FileUpload from 'primevue/fileupload';



const onAdvancedUpload = () => {
    toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};


const isInProgress = ref(true)
const checkData = ref(false)

const active = ref(0);
const checked = ref(false);
const visible = ref(false);

const contactType = ref()
const applicantType = ref()
const salutation = ref()
const brennstoff = ref()

const meinRadio = ref();
const modalInfo = ref("");
const modalHeader = ref("");

const companyLabel = ref();
const adressIsObject = ref(false);



const formData = ref({
    contact: {
        contactType: '',
        salutation: "",
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phonepre: "",
        phone: "",
        mobilepre: "",
        mobile: "",
        street: "",
        number: "",
        zip: "",
        place: "",
        message: "",
    },
    owner: {
        applicantType: '',
        salutation: "",
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phonepre: "",
        phone: "",
        mobilepre: "",
        mobile: "",
        street: "",
        number: "",
        zip: "",
        place: "",
        flatNumber: "",
        flatSize: "",
        message: "",
        vorsteuer: "",
    },
    interest: {
        isfp: false,
        buildingEnergyCertificate: false,
        EWaermeGProofBW: false,
        heatLoadCalculation: false,
        begem: false,
        newBuildingBalance: false,
        initialConsultationWeg: false,
    },
    object: {
        street: '',
        number: '',
        zip: '',
        place: '',
        buildingType: '',
        baujahr: '',
        freestanding: '',
        denkmalschutz: '',
        mehrereGebaeude: '',
        lueftungsanlage: '',
        countWE: '',
        involvedWe: '',
        newCountWE: '',
        newWe: false,
        gewerbeflaeche: '',
        wohnflaeche: '',
        heatingType: '',
        heatingFuel: '',
        heatingConsumption: '',
        heatingYear: '',
        heatingYearUnknown: false,
    },
    measuresImplemented: {
        daemmungWaende: false,
        daemmungDach: false,
        daemmungKeller: false,
        fenster: false,
        aussentuer: false,
        pvAnlage: false,
        thermischeSolaranlage: false,
        smartHome: false,
        austauschHeizung: false,
        heizungsoptimierung: false,
        sommerlicherWaermeschutz: false,
        brennstoffzellenheizung: false,
        biomasseheizung: false,
        eeHybridheizung: false,
        anschlussWarmenetz: false,
        anschlussGebaeudenetz: false,
        waermepumpe: false,
        anschlussGeb75: false,
        anschlussGeb25: false,
        effizienzhaus: false,
        daemmungWaendeYear: '',
        daemmungDachYear: '',
        daemmungKellerYear: '',
        fensterYear: '',
        aussentuerYear: '',
        pvAnlageYear: '',
        thermischeSolaranlageYear: '',
        smartHomeYear: '',
        austauschHeizungYear: '',
        heizungsoptimierungYear: '',
        sommerlicherWaermeschutzYear: '',
        brennstoffzellenheizungYear: '',
        biomasseheizungYear: '',
        eeHybridheizungYear: '',
        anschlussWarmenetzYear: '',
        anschlussGebaeudenetzYear: '',
        waermepumpeYear: '',
        anschlussGeb75Year: '',
        anschlussGeb25Year: '',
        effizienzhausYear: '',
        sonstiges: false,
        sonstigesMessage: '',

    },
    measuresPlanned: {
        daemmungWaende: false,
        daemmungDach: false,
        daemmungKeller: false,
        fenster: false,
        aussentuer: false,
        pvAnlage: false,
        thermischeSolaranlage: false,
        smartHome: false,
        austauschHeizung: false,
        heizungsoptimierung: false,
        sommerlicherWaermeschutz: false,
        brennstoffzellenheizung: false,
        biomasseheizung: false,
        eeHybridheizung: false,
        anschlussWarmenetz: false,
        anschlussGebaeudenetz: false,
        waermepumpe: false,
        anschlussGeb75: false,
        anschlussGeb25: false,
        effizienzhaus: false,
        sonstiges: false,
        sonstigesMessage: '',
    },

})

let hasInterest = true;

function checkForm() {
    console.log(JSON.stringify(formData));

    isInProgress.value = false;
    checkData.value = true;


    // // console.log(formData.value.interest); 
    // hasInterest = checkInterests();
    // if (!hasInterest) {
    //     // 1. HEader rot färben und Hinweis
    // }

    // console.log(hasInterest + 'has int')
}

function checkInterests() {
    hasInterest = Object.values(formData.value.interest).some(value => value === true);
}

// Object
let isObjectStreetEmpty = false;
let isObjectNumberEmpty = false;
let isObjectZipEmpty = false;
let isObjectPlaceEmpty = false;
let isNoType = false;
let isFreestandingEmpty = false;
let isDenkmalschutzEmpty = false;
let isMehrereGebaeudeEmpty = false;
let hasLueftungsanlageEmpty = false;
let objectBauantragEmpty = false;
let objectCountWEEmpty = false;
let involvedWEEmpty = false;
let gewerbeflaecheEmpty = false;
let wohnflaecheEmpty = false;

function checkSecondTab() {
    checkObjectStreet();
    checkObjectNumber()
    checkObjectZip()
    checkObjectPlace()
    checkObjectType();
    checkObjectFreestanding();
    checkObjectDenkmalschutz()
    checkMehrereGebaeude()
    checkLueftungsanlage()
    checkObjectBauantrag();

    if (formData.value.object.buildingType == 'wg' || formData.value.object.buildingType == 'mixed') {
        checkObjectCountWE()
    }

    if (formData.value.object.buildingType == 'wg' || formData.value.object.buildingType == 'mixed') {
        if (formData.value.interest.begem) {
            checkObjectInvolvedWE()
        }
    }

    if (formData.value.object.buildingType == 'nwg' || formData.value.object.buildingType == 'mixed') {
        checkGewerbeflaeche()
    }

    if (formData.value.object.buildingType == 'mixed') {
        checkWohnflaeche()
    }

}

function checkObjectStreet() {
    isObjectStreetEmpty = formData.value.object.street == '' ? true : false;
}

function checkObjectNumber() {
    isObjectNumberEmpty = formData.value.object.number == '' ? true : false;
}

function checkObjectZip() {
    isObjectZipEmpty = formData.value.object.zip == '' ? true : false;
    if (!isObjectZipEmpty) {

    }
}

function checkObjectType() {
    isNoType = formData.value.object.buildingType == '' ? true : false;
}

function checkObjectPlace() {
    isObjectPlaceEmpty = formData.value.object.place == '' ? true : false;

}

function checkObjectFreestanding() {
    isFreestandingEmpty = formData.value.object.freestanding == '' ? true : false;
}

function checkObjectDenkmalschutz() {
    isDenkmalschutzEmpty = formData.value.object.denkmalschutz == '' ? true : false;
}

function checkMehrereGebaeude() {
    isMehrereGebaeudeEmpty = formData.value.object.mehrereGebaeude == '' ? true : false;
}

function checkLueftungsanlage() {
    hasLueftungsanlageEmpty = formData.value.object.lueftungsanlage == '' ? true : false;;
}

function checkObjectBauantrag() {
    objectBauantragEmpty = formData.value.object.baujahr == '' ? true : false;;
}

function checkObjectCountWE() {
    objectCountWEEmpty = formData.value.object.countWE == '' ? true : false;
}

function checkObjectInvolvedWE() {

    involvedWEEmpty = formData.value.object.involvedWe == '' ? true : false;
}

function checkGewerbeflaeche() {
    gewerbeflaecheEmpty = formData.value.object.gewerbeflaeche == '' ? true : false;
}

function checkWohnflaeche() {
    wohnflaecheEmpty = formData.value.object.wohnflaeche == '' ? true : false;
}

// Contact
let isContactSalutationEmpty = false;
let isContactFirstNameEmpty = false;
let isContactLastNameEmpty = false;
let isContactStreetEmpty = false;
let isContactNumberEmpty = false;
let isContactZipEmpty = false;
let isContactPlaceEmpty = false;
let isContactPrephoneEmpty = false;
let isContactPhoneEmpty = false;
let isContactPremobileEmpty = false;
let isContactMobileEmpty = false;
let isContactMailEmpty = false;

// Owner
let isOwnerSalutationEmpty = false;
let isOwnerFirstNameEmpty = false;
let isOwnerLastNameEmpty = false;
let isOwnerStreetEmpty = false;
let isOwnerNumberEmpty = false;
let isOwnerZipEmpty = false;
let isOwnerPlaceEmpty = false;
let isOwnerPrephoneEmpty = false;
let isOwnerPhoneEmpty = false;
let isOwnerPremobileEmpty = false;
let isOwnerMobileEmpty = false;
let isOwnerMailEmpty = false;

function checkFourthTab() {

    // Verwalter Felder nur prüfen, wenn Verwalter
    if (formData.value.contact.contactType.code == 'verwalter') {
        checkContactSalutation();
        checkContactFirstName()
        checkContactLastName()
        checkContactStreet()
        checkContactNumber()
        checkContactZip()
        checkContactPlace()
        checkContactPrephone()
        checkContactPhone()
        checkContactPremobile()
        checkContactMobile()
        checkContactMail()
    }

    checkOwnerSalutation();
    checkOwnerFirstName()
    checkOwnerLastName()
    checkOwnerStreet()
    checkOwnerNumber()
    checkOwnerZip()
    checkOwnerPlace()
    checkOwnerPrephone()
    checkOwnerPhone()
    checkOwnerPremobile()
    checkOwnerMobile()
    checkOwnerMail()
}

function checkContactSalutation() {
    isContactSalutationEmpty = formData.value.contact.salutation == '' ? true : false;
}

function checkContactFirstName() {
    isContactFirstNameEmpty = formData.value.contact.firstName == '' ? true : false;
}

function checkContactLastName() {
    isContactLastNameEmpty = formData.value.contact.lastName == '' ? true : false;
}
function checkContactStreet() {
    isContactStreetEmpty = formData.value.contact.street == '' ? true : false;
}
function checkContactNumber() {
    isContactNumberEmpty = formData.value.contact.number == '' ? true : false;
}
function checkContactZip() {
    isContactZipEmpty = formData.value.contact.zip == '' ? true : false;
}
function checkContactPlace() {
    isContactPlaceEmpty = formData.value.contact.place == '' ? true : false;
}
function checkContactPrephone() {
    isContactPrephoneEmpty = formData.value.contact.phonepre == '' ? true : false;
}
function checkContactPhone() {
    isContactPhoneEmpty = formData.value.contact.phone == '' ? true : false;
}
function checkContactPremobile() {
    isContactPremobileEmpty = formData.value.contact.mobilepre == '' ? true : false;
}
function checkContactMobile() {
    isContactMobileEmpty = formData.value.contact.mobile == '' ? true : false;
}
function checkContactMail() {
    isContactMailEmpty = formData.value.contact.email == '' ? true : false;
}

// owner
function checkOwnerSalutation() {
    isOwnerSalutationEmpty = formData.value.owner.salutation == '' ? true : false;
}

function checkOwnerFirstName() {
    isOwnerFirstNameEmpty = formData.value.owner.firstName == '' ? true : false;
}

function checkOwnerLastName() {
    isOwnerLastNameEmpty = formData.value.owner.lastName == '' ? true : false;
}
function checkOwnerStreet() {
    isOwnerStreetEmpty = formData.value.owner.street == '' ? true : false;
}
function checkOwnerNumber() {
    isOwnerNumberEmpty = formData.value.owner.number == '' ? true : false;
}
function checkOwnerZip() {
    isOwnerZipEmpty = formData.value.owner.zip == '' ? true : false;
}
function checkOwnerPlace() {
    isOwnerPlaceEmpty = formData.value.owner.place == '' ? true : false;
}
function checkOwnerPrephone() {
    isOwnerPrephoneEmpty = formData.value.owner.phonepre == '' ? true : false;
}
function checkOwnerPhone() {
    isOwnerPhoneEmpty = formData.value.owner.phone == '' ? true : false;
}
function checkOwnerPremobile() {
    isOwnerPremobileEmpty = formData.value.owner.mobilepre == '' ? true : false;
}
function checkOwnerMobile() {
    isOwnerMobileEmpty = formData.value.owner.mobile == '' ? true : false;
}
function checkOwnerMail() {
    isOwnerMailEmpty = formData.value.owner.email == '' ? true : false;
}







function fillAdress(adressIsObject) {

    console.log(adressIsObject)
    console.log(JSON.stringify(formData))

    if (adressIsObject) {
        this.formData.owner.street = this.formData.object.street;
        this.formData.owner.number = this.formData.object.number;
        this.formData.owner.zip = this.formData.object.zip;
        this.formData.owner.place = this.formData.object.place;
    }
}


onMounted(() => {
    FormService.getContactType().then((data) => {
        contactType.value = data
    })
    FormService.getApplicantType().then((data) => {
        applicantType.value = data
    })
    FormService.getSalutation().then((data) => {
        salutation.value = data
    })
    FormService.getBrennstoff().then((data) => {
        brennstoff.value = data
    })
})

</script>

<style lang="sass" scoped>
#documents-list
    li::before
        display: inline-block
        content: ""
        width: 12px
        height: 12px
        background-image: url('/storage/app/media/Theme/punkt-list-style.svg')
        background-size: cover
        margin-right: 8px

</style>


<style></style>