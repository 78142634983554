<template>

    <div class="flex items-center justify-start mb-4">
        <div class="mr-2">
            <svg class="w-4"
                 viewBox="0 0 11.347825 11.347829"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m 0,0 h 7.221343 c 2.2789943,0 4.126482,1.84749 4.126482,4.126486 v 3.094861 c 0,2.278994 -1.8474877,4.126482 -4.126482,4.126482 H 4.126482 C 1.84749,11.347829 0,9.500341 0,7.221347 Z"
                    fill="#1f9451"/>
            </svg>
        </div>
        <div>
            <component :is="tag" class="font-bold text-lg uppercase text-black">
                {{ headingText }}
            </component>
            <div v-if="props.subHeadingText" class="text-sm text-neutral-500">
                {{ subHeadingText }}
            </div>
        </div>
    </div>

</template>

<script setup>
const props = defineProps({
                              headingText   : String,
                              subHeadingText: String,
                              tag           : {
                                  type   : String,
                                  default: "div",
                              },

                          })
</script>