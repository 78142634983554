export default {
    root: ({ context, props, parent }) => ({
        class: [
            // Font
            'leading-none',

            // Spacing
            'm-0',
            'py-2 px-3',

            // Shape
            'rounded-md',

            // Colors
            'text-zinc-800 dark:text-white/80',
            'placeholder:text-zinc-400 dark:placeholder:text-zinc-500',
            { 'bg-zinc-0 dark:bg-zinc-950': !context.disabled },
            'border',
            { 'border-zinc-300 dark:border-zinc-600': !props.invalid },

            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            { 'border-red-500 dark:border-red-400': props.invalid },

            // States
            {
                'hover:border-zinc-400 dark:hover:border-zinc-600': !context.disabled && !props.invalid,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-accent-500 dark:focus:ring-accent-400 focus:z-10': !context.disabled,
                'bg-zinc-200 dark:bg-zinc-700 select-none pointer-events-none cursor-default': context.disabled
            },

            // Filled State *for FloatLabel
            { filled: parent.instance?.$name == 'FloatLabel' && props.modelValue !== null && props.modelValue?.length !== 0 },

            // Misc
            'appearance-none',
            'transition-colors duration-200'
        ]
    })
};
