export default {
    content: {
        class: [
            // Spacing
            'p-0',

            // Shape
            'border-0',

            // Color
            'text-zinc-700 dark:text-white/80',
            'bg-zinc-0 dark:bg-zinc-900'
        ]
    },
    grid: {
        class: [
            // flex
            'flex flex-wrap',

            // Spacing
            'ml-0 mr-0 mt-0',

            // Color
            'bg-zinc-0 dark:bg-zinc-900'
        ]
    },
    header: {
        class: [
            'font-semibold',

            // Spacing
            'py-3 px-4',

            // Color
            'text-zinc-800 dark:text-white/80',
            'bg-zinc-00 dark:bg-zinc-900',
            'border-b border-zinc-200 dark:border-zinc-700'
        ]
    }
};
