export const  FormService = {
    getContactTypeData() {
        return [
                {
                    name: "Verwalter (z.B. Hausverwaltung)",
                    code: "verwalter",
                },
                {
                    name: "Hauseigentümer",
                    code: "hauseigentümer",
                },
                {
                    name: "Wohnungseigentümer",
                    code: "wohnungseigentuemer",
                },
            ]
    },

    getApplicantTypeData() {
        return [
            {
                code: "privatperson",
                name: "Privatperson",
            },
            {
                code: "freiberufler",
                name: "Freiberuflich tätige Person",
            },
            {
                code: "unternehmen",
                name: "Unternehmen",
            },
            {
                code: "unternehmen-kommunal",
                name: "Unternehmen mit kommunaler Beteiligung",
            },
            {
                code: "privatunternehmen",
                name: "Privatunternehmen",
            },
            {
                code: "verein",
                name: "Eingetragener Verein",
            },
            {
                code: "schule",
                name: "Schule, Forschungsverein oder dessen Träger",
            },
            {
                code: "kirche",
                name: "Kirche, kirchliche Einrichtung, anerkannte Religionsgemeinschaft",
            },
            {
                code: "krankenhaus",
                name: "Krankenhaus",
            },
            {
                code: "landwirtschaft",
                name: "Landwirtschaft, Forstwirtschaft, Gartenbau",
            },
            {
                code: "contractor",
                name: "Contractor",
            },
            {
                code: "gemeinnuetzige-organisation",
                name: "Gemeinnützige Organisation",
            },
            {
                code: "kommunale-gebietskoerperschaft",
                name: "Kommunale Gebietskörperschaft",
            },
            {
                code: "gebietskoerperschaft",
                name: "Gebietskörperschaft",
            },
            {
                code: "zweckverband",
                name: "Zweckverband",
            },
            {
                code: "eigenbetrieb",
                name: "Eigenbetrieb",
            },
            {
                code: "stiftung",
                name: "Stiftung",
            },
            {
                code: "koerperschaft",
                name: "Körperschaft",
            },
            {
                code: "zusammenschluss-privatpersonen",
                name: "Kommune",
            },
            {
                code: "wohnungseigentuemergemeinschaft",
                name: "Wohnungseigentümergemeinschaft",
            },
            {
                code: "kommunaler-zweckverband",
                name: "Kommunaler Zweckverband",
            },
            {
                code: "einzelunternehmen",
                name: "Einzelunternehmen",
            },
            {
                code: "wohnungsbaugenossenschaft",
                name: "Wohnungsbaugenossenschaft",
            },
            {
                code: "kommunaler-eigenbetrieb",
                name: "Kommunaler Eigenbetrieb für Daseinsvorsorge",
            },
            {
                code: "niessbrauchsberechtigter",
                name: "Nießbrauchsberechtigter (Vermieter)",
            },
        ]
    },

    getSalutationData() {
        return [
            {
                code: "herr",
                name: "Herr",
            },
            {
                code: "frau",
                name: "Frau",
            },
            {
                code: "firma",
                name: "Firma",
            },
        ]
    },

    getBrennstoffData() {
        return [
                {
                    name: "Gas",
                    code: "gas",
                },
                {
                    name: "Heizöl",
                    code: "heizoel",
                },
                {
                    name: "Holzkohle",
                    code: "holzkohle",
                },
                {
                    name: "Stückholz",
                    code: "steuckholz",
                },
                {
                    name: "Pellet",
                    code: "pellet",
                },
                {
                    name: "Strom",
                    code: "strom",
                },
            ]
    },

    getContactType() {
        return Promise.resolve(this.getContactTypeData())
    },

    getApplicantType() {
        return Promise.resolve(this.getApplicantTypeData())
    },
    getSalutation() {
        return Promise.resolve(this.getSalutationData())
    },
    getBrennstoff() {
        return Promise.resolve(this.getBrennstoffData())
    },


}