<template>

    {{ props.seba }}
    <p>{{ props.october }}</p>


    <HeadingText headingText='Ich interessiere mich für ...' subHeadingText='Lorem ipsum dolor sit amet, consectetur adipisicing elit.'/>
    <section>
        <div class="block lg:flex">
            <div class="w-1/2 mb-2 lg:mb-0">
                <div class="flex flex-col gap-2">

                    <div class="flex align-items-center">
                        <Checkbox v-model="formData.interest.isfp" inputId="isfp" binary/>
                        <label for="isfp" class="ml-2 cursor-pointer">Individueller Sanierungsplan (iSFP)</label>
                    </div>

                    <div class="flex align-items-center">
                        <Checkbox v-model="formData.interest.buildingEnergyCertificate" inputId="buildingEnergyCertificate" binary/>
                        <label for="buildingEnergyCertificate" class="ml-2 cursor-pointer">Gebäudeenergieausweis</label>
                    </div>

                </div>
            </div>

            <div class="w-1/2">
                <div class="flex flex-col gap-2">

                    <div class="flex align-items-center">
                        <Checkbox v-model="formData.interest.BEGFunding" inputId="BEGFunding" binary/>
                        <label for="BEGFunding" class="ml-2 cursor-pointer">BEG Fördermittelabwicklung</label>
                    </div>

                    <div class="flex align-items-center">
                        <Checkbox v-model="formData.interest.newBuildingBalance" inputId="newBuildingBalance" binary/>
                        <label for="newBuildingBalance" class="ml-2 cursor-pointer">Neubaubilanzierung</label>
                    </div>

                </div>
            </div>

        </div>
    </section>

    <section>
        <div id="documents" class="flex flex-col">

            <!-- Dokumente Container -->
            <div id="container-dokumente" class="mb-8">
                <p class="my-2 font-bold">Bitte halten Sie folgende Dokumente in digitaler Form bereit:</p>
                <ul id="documents-list">
                    <li id="lageplan">Baubeschreibung + Lageplan</li>
                    <li id="grundrisse">Grundrisse (PDF`s mit Maßketten)</li>
                    <li id="Schornsteinfegermessprotokoll">Schornsteinfeger ermessprotokoll</li>
                    <li id="ansichten">Ansichten &amp; Schnitte</li>
                    <li id="angebote">Angebote/Rechnungen von Maßnahmen</li>
                    <li id="fotos">Fotos Gebäude &amp; Heizung</li>
                </ul>
                <p class="my-2">Falls Sie diese Dokumente im Moment nicht zur Hand haben, ist das kein Problem.<br>Sie können Sie diese auch später postalisch oder per E-Mail nachreichen. <strong>Bitte beachten Sie, dass wir Ihre Anfrage erst bearbeiten können, wenn uns alle Daten vorliegen.</strong></p>
            </div>
            <!-- Ende Dokumente Containaer -->
        </div>
    </section>

    <section>

        <HeadingText heading-text="Ich stelle diese Anfrage als ..." :tag="'h2'"/>
        <Dropdown :options='contactType' v-model='formData.contactType' option-label='name' placeholder='Bitte wählen'/>

        <p class="py-4 font-bold">ich bin</p>

        <Dropdown :options='applicantType' v-model='formData.applicantType' option-label='name' placeholder='Bitte wählen'/>


    </section>

</template>

<script setup lang='js'>
import {onMounted, ref} from "vue";
import {FormService} from "../services/FormService.js"

import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import HeadingText from "./ui/HeadingText.vue";

const contactType   = ref()
const applicantType = ref()
const salutation    = ref()


const props = defineProps({
    seba: {
        type: String
    },
    october: {
        type: String,
        default: 'nix'
    }
})

const formData = ref({
                         contactType  : "",
                         applicantType: "",
                         salutation   : "",
                         firstName    : "",
                         lastName     : "",
                         email        : "",
                         phone        : "",
                         address      : "",
                         city         : "",
                         state        : "",
                         zip          : "",
                         country      : "",
                         message      : "",
                         interest     : {
                             isfp                     : false,
                             buildingEnergyCertificate: false,
                             EWaermeGProofBW          : false,
                             heatLoadCalculation      : false,
                             BEGFunding               : false,
                             newBuildingBalance       : false,
                             initialConsultationWeg   : false,
                         },
                     })

onMounted(() => {
    FormService.getContactType().then((data) => {
        contactType.value = data
    })
    FormService.getApplicantType().then((data) => {
        applicantType.value = data
    })
    FormService.getSalutation().then((data) => {
        salutation.value = data
    })
})

</script>

<style lang="sass" scoped>
#documents-list
    li::before
        display: inline-block
        content: ""
        width: 12px
        height: 12px
        background-image: url('/storage/app/media/Theme/punkt-list-style.svg')
        background-size: cover
        margin-right: 8px

</style>