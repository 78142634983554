export default {
    root: ({ props, context, parent }) => ({
        class: [
            // Font
            'leading-none',

            // Flex
            { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

            // Spacing
            'm-0',
            {
                'py-3 px-3.5': props.size == 'large',
                'py-1 px-1.5': props.size == 'small',
                'py-2 px-3': props.size == null
            },

            // Shape
            { 'rounded-md': parent.instance.$name !== 'InputGroup' },
            { 'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup' },
            { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
            { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },

            // Colors
            'text-zinc-800 dark:text-white/80',
            'placeholder:text-zinc-400 dark:placeholder:text-zinc-500',
            { 'bg-zinc-0 dark:bg-zinc-950': !context.disabled },
            'border',
            { 'border-zinc-300 dark:border-zinc-700': !props.invalid },

            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            { 'border-red-500 dark:border-red-400': props.invalid },

            // States
            {
                'hover:border-zinc-400 dark:hover:border-zinc-600': !context.disabled && !props.invalid,
                'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-accent-500 dark:focus:ring-accent-400 focus:z-10': !context.disabled,
                'bg-zinc-200 dark:bg-zinc-700 select-none pointer-events-none cursor-default': context.disabled
            },

            // Filled State *for FloatLabel
            { filled: parent.instance?.$name == 'FloatLabel' && context.filled },

            // Misc
            'appearance-none',
            'transition-colors duration-200'
        ]
    })
};
