<template>


	<div class="flex flex-col">
		<h1 class="text-2xl sm:text-3xl md:text-4xl font-bold mb-8">Lohnt sich ein <strong
				class="text-primary-500">individueller
				Sanierungsfahrplan (iSFP)</strong> für mich?</h1>

		<div class="text-lg" v-html="parsedText.isfp_form_title"></div>

	</div>

	<div class="flex gap-4 lg:gap-12  w-full flex-wrap lg:flex-nowrap px-2">
		<div class="my-4 md:my-8 w-full lg:w-4/6">

			<div class="mb-4 font-bold">Ihr Angebotspreis inkl. MwSt.</div>
			<Slider :min="isfpSettings.slider.min" :max="isfpSettings.slider.max" :step="isfpSettings.slider.step"
				v-model="sliderValue" @change="onValueChange" />

			<div class="flex g mt-4 justify-between items-center ">
				<div class="text-3xl font-bold text-primary-500">{{ formattedSliderValue }}</div>


				<div class="flex gap-1 ">
					<div @click.prevent="decrement"
						class="p-2 cursor-pointer rounded bg-black text-white w-8 h-8 justify-center items-center">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
							<path fill="currentColor"
								d="m4.431 12.822l13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645" />
						</svg>
					</div>
					<div @click.prevent="increment"
						class="p-2 cursor-pointer rounded bg-black text-white w-8 h-8 justify-center items-center">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
							<path fill="currentColor"
								d="M5.536 21.886a1 1 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886" />
						</svg>
					</div>
				</div>
			</div>
		</div>

		<div class="mb-8 lg:my-8 w-full lg:w-2/6">
			<div class="mb-4 font-bold">Wohneinheiten in Ihrem Gebäude</div>
			<Slider :min="isfpSettings.units.min" :max="isfpSettings.units.max" :step="isfpSettings.units.step"
				v-model="sliderUnits" @change="onWeChange" />
			<div class="mt-4 text-3xl font-bold text-primary-500"> {{ sliderUnits }} Wohneinheit(en)</div>

		</div>

	</div>
	<!-- 
	<div>
		geld: {{ formattedSliderValue }} WE: {{ sliderUnits }}
	</div> -->

	<div class="flex gap-8 lg:gap-8 w-full items-start flex-wrap lg:flex-nowrap">
		<!-- <div class="w-2/6 bg-gray-100 p-4 rounded">
			<div v-html="parsedText.firstBlockText">



			</div>


			<p>angebotWert: {{ sliderValueFormated }}</p>
			<p>wohneinheiten: {{ sliderUnits }}</p>
			<p>förderungMitIsfp: {{ promotionIsfpFormated }}</p>
			<p>förderungOhneIsfp: {{ promotionFormated }}</p>
			<p>maxFörderungIsfp: {{ maxSummIsfpFormated }}</p>
			<p>maxFörderung: {{ maxSummFormated }}</p>
			<p>differenz: {{ differenceFormated }}</p>
			<p>maxFörderungOhneIsfp: {{ maxSummWihoutIsfpFormated() }}</p>
			<p>maxFörderungMitIsfp: {{ maxSummWihtIsfpFormated() }}</p>
			<p>maxRateWithoutIsfp: {{ maxRateWithoutIsfp() }}</p>
			<p>maxRateWithIsfp: {{ maxRateWithIsfp() }}</p>
		</div> -->

		<div
			class=" lg:order-last w-full lg:w-4/6 bg-primary p-4 md:p-8 rounded shadow-2xl shadow-primary-500 text-white relative">
			<div class="text-3xl lg:text-4xl font-bold mb-8">mit Sanierungsfahrplan</div>

			<!-- <div class="w-full flex justify-center my-8">
			</div> -->

			<div class="flex">

				<div class="w-full flex justify- ">

					<div class="my-8 highlight-primary ">
						<div class="font-bold">
							<div class="text-2xl mb-4">Ihre mögliche Förderung</div>
							<div class="text-5xl"><mark>{{ formatCurrency(promotionIsfp) }} </mark></div>
						</div>
					</div>
				</div>

				<div class="w-full flex justify- ">

					<div class="my-8 highlight-primary -rotate-6 lg:absolute top-0 right-0 ">
						<div class=" font-bold bg-white text-black p-4 lg:px-6 lg:py-4 rounded -rotate-12 w-fit">

							<div class="text-xl md:text-3xl">{{ differenceFormated }} </div>
							<div class="text-xl"><strong>zusätzlich</strong> mit iSFP</div>
						</div>
					</div>
				</div>

			</div>

			<!-- <div class="w-full flex justify-center ">
				<div class="text-xl font-bold bg-white text-black px-6 py-4 rounded -rotate-12 my-8">
					<div>{{ formatCurrency(promotion) }}</div>
					<div class="text-sm">Ihre Förderung</div>
				</div>
			</div> -->


			<ul class="text-lg md:text-xl list-with-svg fill-white mt-8">
				<li>Erhöhung der maximalen Fördersumme (+ {{ differenceFormated }})</li>
				<li>Höherer prozentualer Zuschussanteil</li>
				<li>Erhöhte Fördersumme pro Wohneinheit</li>
				<li>Bis zu <strong>50 % </strong> der Kosten werden bezuschusst</li>
				<li>Gültigkeit des iSFP: 15 Jahre</li>
				<li><strong>Keine Verpflichtung</strong> zur Umsetzung</li>
				<li>Abweichende Umsetzungsoptionen möglich</li>
			</ul>

			<div class="flex w-full justify-center">
				<a href="#schnellanfrage"
					class="bg-amber-300 text-black hover:bg-black hover:text-white transition px-6 py-3 w-fit mt-8 rounded-full text-xl">Jetzt
					bis zu <strong> {{
						formatCurrency(promotionIsfp) }} </strong> mit iSFP sichern</a>
			</div>
		</div>

		<div class="w-full lg:w-2/6 bg-black p-4 md:p-8 rounded text-white shadow-lg shadow-gray-700">
			<div class="text-3xl">ohne Sanierungsfahrplan</div>

			<div class="w-full flex justify-center ">
				<div class="text-xl font-bold bg-white text-black px-6 py-4 rounded gradient my-8">
					<div class="text-sm">Ihre mögliche Förderung</div>

					<div>{{ formatCurrency(promotion) }}</div>
				</div>
			</div>

			<div class="flex flex-col gap-4">
				<div class="flex gap-4 jusify-center items-center text-gray-200">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
						<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
							stroke-width="1.5">
							<path d="M9 16c.85-.63 1.885-1 3-1s2.15.37 3 1m-5.5-5.5V10m5 .5V10" />
							<path d="M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0" />
						</g>
					</svg>
					<div class="">Geringere Gesamtsumme der Förderung</div>
				</div>
				<div class="flex gap-4 jusify-center items-center text-gray-200">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
						<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
							stroke-width="1.5">
							<path d="M9 16c.85-.63 1.885-1 3-1s2.15.37 3 1m-5.5-5.5V10m5 .5V10" />
							<path d="M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0" />
						</g>
					</svg>
					<div class="">Geringerer prozentualer Förderanteil</div>
				</div>
			</div>


			<div>
				<!-- 
				<p>angebotWert: {{ sliderValueFormated }}</p>
				<p>wohneinheiten: {{ sliderUnits }}</p>
				<p>förderungMitIsfp: {{ promotionIsfpFormated }}</p>
				<p>förderungOhneIsfp: {{ promotionFormated }}</p>
				<p>maxFörderungIsfp: {{ maxSummIsfpFormated }}</p>
				<p>maxFörderung: {{ maxSummFormated }}</p>
				<p>differenz: {{ differenceFormated }}</p>
				<p>maxFörderungOhneIsfp: {{ maxSummWihoutIsfpFormated() }}</p>
				<p>maxFörderungMitIsfp: {{ maxSummWihtIsfpFormated() }}</p>
				<p>maxRateWithoutIsfp: {{ maxRateWithoutIsfp() }}</p>
				<p>maxRateWithIsfp: {{ maxRateWithIsfp() }}</p> -->
			</div>
		</div>

	</div>


</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import Slider from 'primevue/slider'

const props = defineProps({
	overViewText: String,
	cmsVars: String,
	variables: Object

});

const sliderValue = ref(20000);
const sliderValueFormated = ref('')
const sliderUnits = ref(1);

const maxSummIsfp = ref(0);
const maxSummIsfpFormated = ref('');
const maxSumm = ref(0);
const maxSummFormated = ref('');
const promotion = ref(0);
const promotionFormated = ref('');
const promotionIsfp = ref(0);
const promotionIsfpFormated = ref('');

const differenceFormated = ref('');
const maxSummWihoutIsfpFormated = () => {
	return formatCurrency(isfpSettings.value.withoutIsfp.maxSumm)
}

const maxSummWihtIsfpFormated = () => {
	return formatCurrency(isfpSettings.value.withIsfp.maxSumm)
}

const maxRateWithoutIsfp = () => {
	return isfpSettings.value.withoutIsfp.maxRate
}
const maxRateWithIsfp = () => {
	return isfpSettings.value.withIsfp.maxRate
}

const isfpSettings = ref({
	slider: {
		min: 2000,
		max: 500000,
		step: 1000
	},
	units: {
		min: 1,
		max: 20,
		step: 1
	},
	withoutIsfp: {
		maxSumm: 30000,
		maxRate: 15
	},
	withIsfp: {
		maxSumm: 60000,
		maxRate: 20
	}
});

onMounted(() => {
	maxSummIsfp.value = isfpSettings.value.withIsfp.maxSumm;
	maxSumm.value = isfpSettings.value.withoutIsfp.maxSumm;
	calculatePromotion()
	console.log('cmsVars', JSON.parse(props.cmsVars));
});

function formatCurrency(value: number): string {
	return new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	}).format(value);
}

const formattedSliderValue = computed(() => {
	return formatCurrency(sliderValue.value);

});

function onWeChange() {
	calculatePromotion()
}

function onValueChange() {
	calculatePromotion();
}

function calculatePromotion() {

	maxSummIsfp.value = isfpSettings.value.withIsfp.maxSumm * sliderUnits.value;
	maxSumm.value = isfpSettings.value.withoutIsfp.maxSumm * sliderUnits.value;


	// Ergänzung Mareike
	// sliderValue ist Angebotspreis



	// if (sliderValue.value <= maxSummIsfp.value) { **Fehler korrigiert am 10.10.24**
	if (sliderValue.value <= maxSumm.value) {
		promotion.value = ((sliderValue.value * isfpSettings.value.withoutIsfp.maxRate) / 100)
	} else {
		promotion.value = maxSumm.value * isfpSettings.value.withoutIsfp.maxRate / 100;

	}

	if (sliderValue.value <= maxSummIsfp.value){
		promotionIsfp.value = ((sliderValue.value * isfpSettings.value.withIsfp.maxRate) / 100)

	} else{
		promotionIsfp.value = maxSummIsfp.value * isfpSettings.value.withIsfp.maxRate / 100;
	}




	promotionIsfpFormated.value = formatCurrency(promotionIsfp.value);
	promotionFormated.value = formatCurrency(promotion.value);
	maxSummIsfpFormated.value = formatCurrency(maxSummIsfp.value);
	maxSummFormated.value = formatCurrency(maxSumm.value);
	differenceFormated.value = formatCurrency(promotionIsfp.value - promotion.value);
	sliderValueFormated.value = formatCurrency(sliderValue.value);

}

const increment = () => {
	if (sliderValue.value > isfpSettings.value.slider.max || sliderValue.value < isfpSettings.value.slider.min) {
		return;
	}
	sliderValue.value += isfpSettings.value.slider.step;
	calculatePromotion()
}
const decrement = () => {
	if (sliderValue.value > isfpSettings.value.slider.max || sliderValue.value < isfpSettings.value.slider.min) {
		return;
	}
	sliderValue.value -= isfpSettings.value.slider.step;
	calculatePromotion()
}
const testVars2 = ref({
	// 'sliderValue'  : ref(sliderValue),
	'angebotWert': ref(sliderValueFormated),
	'wohneinheiten': ref(sliderUnits),
	'förderungIsfp': ref(promotionIsfpFormated),
	'förderung': ref(promotionFormated),
	'maxFörderungIsfp': ref(maxSummIsfpFormated),
	'maxFörderung': ref(maxSummFormated),
	'differenz': ref(differenceFormated),
	'standardMaxFörderung': ref(maxSummWihoutIsfpFormated()),
	'standardMaxFörderungIsfp': ref(maxSummWihtIsfpFormated()),
	'standardMaxProzent': ref(maxRateWithoutIsfp()),
	'standardMaxProzentIsfp': ref(maxRateWithIsfp())
})


const replacePlaceholders = (text: string, variables: Record<string, any>) => {
	let result = text;
	for (const [key, value] of Object.entries(variables)) {
		// const regex = new RegExp(key, 'g');
		const regex = new RegExp(`##${key}##`, 'g');
		result = result.replace(regex, value.toString());
	}
	return result;
};

const parsedText = computed(() => {
	const cmsTextVars = JSON.parse(props.cmsVars);
	let parsedCmsText = {
		isfp_form_title: replacePlaceholders(cmsTextVars.isfp_form_title, testVars2.value),
		firstBlockText: replacePlaceholders(cmsTextVars.first_block, testVars2.value),
	}

	// return replacePlaceholders(props.overViewText, testVars2.value);
	return parsedCmsText
});

</script>

<style lang="sass" scoped>


.list-with-svg
	li
		position: relative
		padding-left: 2em

		&::before
			content: ''
			position: absolute
			left: 0
			top: 50%
			transform: translateY(-50%)
			width: 1.5em
			height: 1.5em
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="white" d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4z"/></svg>') no-repeat center center
			background-size: contain

mark
	margin: 0 -.4em
	padding: .1em .4em
	border-radius: .8em 1.3em
	background: transparent
	background-image: linear-gradient(58deg, rgba(245, 255, 243, 0.5662640056022409) 5%, rgb(139, 248, 71) 15%, rgb(139, 248, 71) 65%, rgba(139, 248, 71, 0.34) 93%)
	-webkit-box-decoration-break: clone
	box-decoration-break: clone

</style>