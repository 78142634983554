<template>

    <section>


        <div class="container mx-auto flex flex-col py-8 px-2 lg:py-16 gap-4">


            <div class="headlines mb-4">
                <!-- smallline -->

                <div class="flex items-center">
                    <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="w-4 xl:w-6 mr-2">
                        <path
                            d="M0 0.0341797H35C46.0457 0.0341797 55 8.98849 55 20.0342V35.0342C55 46.0799 46.0457 55.0342 35 55.0342H20C8.95431 55.0342 0 46.0799 0 35.0342V0.0341797Z"
                            fill="#1F9451" />
                    </svg>

                    <div class="text-sm md:text-xl">
                        {{ props.smallline }}
                    </div>
                </div>

                <!-- headline -->
                <h2 class="font-bold text-2xl xl:text-4xl uppercase">
                    {{ props.headline }}
                </h2>

                <!-- subline -->
                <h3 class="text-2xl font-light xl:text-4xl uppercase">
                    {{ props.subline }}
                </h3>
            </div>


            <div class="text-sm sm:text-base">
                {{ props.content }}
            </div>


            <div class="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-6 gap-16  ">
                <!-- Icons -->
                <div  v-for="icon in props.icons" class="transition group  relative flex flex-col items-stretch  justify-start z-30 ">
                    <div class="grow flex flex-col justify-start items-center py-2">
                        <img class="h-20 mb-2" :src="getFullIconUrl(icon.icon_url)" :alt="icon.alt_tag" />
                        <div class="  content z-10 w-full text-center md:text-left md:w-fit">
                            <div class="text-sm lg:text-base text-center text-gray-700" v-html="icon.text"></div>
                        </div>
                    </div>
                    <!-- <div class="shrink-0 flex justify-center items-center">
                        <Button label="Details" @click="changeDialogVar(icon.explanation)" />
                    </div> -->
                </div>

            </div>

        </div>

    </section>

    <Dialog v-model:visible="visible" modal footer="bla" header="Header" :style="{ width: '50vw' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

        <template #header>
            <div class="px-4">
                
            </div>
        </template>


        <p class="m-0">
            <!-- {{ dialogVar }} -->
        <div v-html="dialogVar"></div>
        </p>

        <template #footer>
            <div class="px-4">
                
            </div>
        </template>
    </Dialog>

</template>

<script setup lang='js'>
import { onMounted, ref, toRaw } from "vue";

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import Dropdown from "primevue/dropdown";



const visible = ref(false);
const dialogVar = ref('test var dialog')

function changeDialogVar(e) {
    dialogVar.value = e
    visible.value = true
}

const props = defineProps({
    smallline: {
        type: String,
        default: 'Lorem ipsum'
    },
    smallline_type: {
        type: String,
        default: 'div'
    },
    headline: {
        type: String,
        default: 'Lorem ipsum'
    },
    headline_type: {
        type: String,
        default: 'div'
    },
    subline: {
        type: String,
        default: 'Lorem ipsum'
    },
    subline_type: {
        type: String,
        default: 'div'
    },
    content: {
        type: String,
        default: 'Lorem ipsum'
    },
    icons: {
        type: Array
    },


})

const storagePath = '/storage/app/media/';

const getFullIconUrl = (iconUrl) => {
    return storagePath + iconUrl;
};



onMounted(() => {

})

</script>